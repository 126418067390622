import { LoginTimeoutModal } from '../LoginTimeoutModal/LoginTimeoutModal';
import React from 'react';
import ChatWrapper from '../ChatWrapper';
import AppInt from '../../constants/App.interface';
import AppHelmet from '../AppHelmet';
import AppHeader from '../AppHeader';
import MegaMenu from '../MegaMenu';
import Footer from '../Footer';
import './AppWrapper.scss'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { readLocalFirebaseConfig } from '@capcenter/firebase-service';
import { GoogleLoginModal } from '../GoogleLoginModal/GoogleLoginModal';

const AppWrapper: React.SFC<{
  children: React.ReactElement<any>;
  navHidden?: boolean;
  app?: string;
  appItem?: AppInt;
  industry?: string;
  height100?: boolean;
  noFooter?: boolean;
  noHelmet?: boolean;
  noHeader?: boolean;
  retryLoginCheck?: boolean;
}> = props => {
  const height100Class = props.height100 ? 'h-100 ' : '';
  const noFooterClass = !props.noFooter ? 'cc--content-wrap-with-footer' : ''
  const isNotIframe: boolean = (window.self === window.top);

  return (
    <ChatWrapper>
      <GoogleOAuthProvider clientId={readLocalFirebaseConfig(window.location.hostname).clientId}>
        <>
        <GoogleLoginModal/>
        <LoginTimeoutModal retryLoginCheck={props.retryLoginCheck}/>
                {isNotIframe && !props.navHidden && <MegaMenu app={props.app} appItem={props.appItem} industry={props.industry} />}

                <div
                  id='app-wrapper-content-wrapper'
                  className={ `cc--content-wrap ${height100Class} ${noFooterClass}`}
                >
                  <>
                    {!props.noHelmet && <AppHelmet app={props.app} appItem={props.appItem} />}
                    {!props.noHeader && <AppHeader app={props.app} appItem={props.appItem} />}
                    {props.children}
                  </>
                </div>
                {isNotIframe && !props.noFooter  && <Footer {...props} />}
        </>
      </GoogleOAuthProvider>
    </ChatWrapper>
  );
};

export default AppWrapper;
