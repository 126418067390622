import React, { useEffect, createRef, useState } from 'react';
import {
  HamburgerMenuSVG,
  CloseButtonSVG,
  SearchSVG,
  CapcenterHouseSVG
} from './MegaMenuSVG'
import AppList from '@capcenter/shared/src/constants/AppList.json';
import MegaMenuJson from './mega-menu.json';
import './style/MegaMenu.scss';
import SignInModal from './SignInModal/SignIn';
import AppInt from "../../constants/App.interface";
import { getUrlWithUtmQueryString } from '@capcenter/shared'

function AppNav(props: {
  appItem?: AppInt,
  app?: string
  industry?: string
}) {
  const [showMobileDropdownMenu, setShowMobileDropdownMenu] = useState(false);
  const [showSignInModal, setShowSignInModal] = React.useState(false);
  const [navigationIndustry, setNavigationIndustry] = useState('Mortgage');

  const handleCloseMyAccountModal = () => {
    setShowSignInModal(false);
	};

  const handleShowMyAccountModal = () => {
    setShowSignInModal(true);
	};

  let navigationMenuRef = createRef<HTMLHtmlElement>();
  let mobileDropdownMenuRef = createRef<HTMLDivElement>();

  let topMenuItems: any[] = [];

  let mobileDropdownMenu: any[] = [];
  let mobileDropdownMenuCards: any[] = [];
  let mobileDropdownMenuLeftTextMenu: any[] = [];
  let mobileDropdownMenuLeftTextMenuItems: any[] = [];
  let mobileDropdownMenuRightTextMenu: any[] = [];
  let mobileDropdownMenuRightTextMenuItems: any[] = [];

  const capcenterLogo = require('./assets/img/capcenter-white-text-transparent-bg.png');

  let baseUrls : any = {
    "Mortgage": "",
    "Mortgage-link": "",
    "Refinance": "",
    "Refinance-link": "",
    "Home Equity": "",
    "Home Equity-link": "",
    "Realty": "",
    "Realty-link": "",
    "Homes": "",
    "homes-link": "",
    "Insurance": "",
    "Insurance-link": "",
    "Espanol": "",
    "Espanol-link": "",
    "About": "",
    "About-link": "",
    "Sign in": "",
    "Sign in-link": ""
  };

  const setBaseUrls = function () {
    const lowerCaseURLHost = window.location.host.toLowerCase();

    MegaMenuJson.forEach(function (menu: any) {

      if (menu.menu === 'Realty') {
        if (lowerCaseURLHost.startsWith('localhost')) {
          baseUrls['Homes'] = menu.homesBaseUrl.local;
        } else if (lowerCaseURLHost.startsWith('dev')) {
          baseUrls['Homes'] = menu.homesBaseUrl.dev;
        } else if (lowerCaseURLHost.startsWith('qa')) {
          baseUrls['Homes'] = menu.homesBaseUrl.qa;
        } else if (lowerCaseURLHost.startsWith('www') ||lowerCaseURLHost.startsWith('capcenter')) {
          baseUrls['Homes'] = menu.homesBaseUrl.prod;
        }
      }

      baseUrls[menu.menu + '-link'] = menu.link;

      if (lowerCaseURLHost.startsWith('localhost')) {
        baseUrls[menu.menu] = menu.baseUrl.local;
      } else if (lowerCaseURLHost.startsWith('dev')) {
        baseUrls[menu.menu] = menu.baseUrl.dev;
      } else if (lowerCaseURLHost.startsWith('qa')) {
        baseUrls[menu.menu] = menu.baseUrl.qa;
      } else if (lowerCaseURLHost.startsWith('www') ||lowerCaseURLHost.startsWith('capcenter')) {
        baseUrls[menu.menu] = menu.baseUrl.prod;
      }
    });
  }

  setBaseUrls();

  const setIndustryContext = function () {
    if (props.industry) {
      setNavigationIndustry(props.industry);
    } else if (props.appItem && props.appItem?.industry) {
      setNavigationIndustry(props.appItem.industry);
    } else {
      // Figure out from URL
      // Default if not found

      let navigationIndustryFound = false;

      Object.values(AppList).forEach(function (appListItem : any) {
        if (appListItem.url === window.location.pathname && appListItem.industry) {
          setNavigationIndustry(appListItem.industry);
          navigationIndustryFound =  true;
        }
      });

      if (!navigationIndustryFound || window.location.pathname === '/') {
        if (window.location.host.indexOf('capcenterhomes.com') >= 0 || window.location.host.indexOf('capcenterrealty.com') >= 0 ) {
          setNavigationIndustry('Realty');
        } else if (window.location.host.indexOf('capcenterinsurance.com') >= 0) {
          setNavigationIndustry('Insurance');
        }
      }
    }
  };

  const setActiveIndustryContext = function () {
    let elems = navigationMenuRef.current?.getElementsByClassName('industry-menu-item');

    if (elems) {
      for (let i = 0; i < elems.length; i++) {
        // @ts-ignore
        const elem : any = elems.item(i);
        if (elem.dataset.id === navigationIndustry) {
          elem.classList.remove('active');
          elem.classList.add('active');
        } else {
          elem.classList.remove('active');
        }
      }
    }
  }

  useEffect(() => {
    setIndustryContext();
    setActiveIndustryContext();
  });

  const clickHamburgerMenu = function () {
    setShowMobileDropdownMenu(!showMobileDropdownMenu);
  };

  topMenuItems = [];
  MegaMenuJson.forEach(function (menu) {
    if (navigationIndustry === menu.menu) {
      topMenuItems.push(
        <li key={menu.id} className='cc-nav-brand cc-lower-menu-nav-brand'>
          <div className={'d-inline-block'}>
            <span className='d-lg-none'>{CapcenterHouseSVG} CapCenter</span>
            <span className='d-none d-lg-block'>{menu.industry ?? ""}</span>
          </div></li>);

      menu.submenu.forEach(function (navigationItem) {
        let navigationItemLink = navigationItem.link;

        if (menu.id === 'menu-realty') {
          if (navigationItem.id.indexOf("realty-search-homes-for-sale") > -1) {
            navigationItemLink = baseUrls['Homes'] + navigationItem.link;
          } else {
            navigationItemLink = baseUrls['Realty'] + navigationItem.link;
          }
        }

        topMenuItems.push(<li key={navigationItem.id} className={"menu-item "}>
          <a href={getUrlWithUtmQueryString(navigationItemLink)} title={navigationItem.caption} target={navigationItem.linkTarget}><div>{navigationItem.title}</div></a>
        </li>);
      });
    }
  });

  MegaMenuJson.forEach(function (menu) {

    if (menu.menu !== 'Sign in') {
      menu.submenu.forEach(function (navigationItem) {
        let navigationItemLink = navigationItem.link;

        if (menu.id === 'menu-realty') {
          if (navigationItem.id.indexOf("realty-search-homes-for-sale") > -1) {
            navigationItemLink = baseUrls['Homes'] + navigationItem.link;
          } else {
            navigationItemLink = baseUrls['Realty'] + navigationItem.link;
          }
        }

        mobileDropdownMenuCards.push(
          <a key={navigationItem.title} href={getUrlWithUtmQueryString(navigationItemLink)} target={navigationItem.linkTarget} title={navigationItem.caption}>
            <div className="cc-nav-mobile-dropdown-menu-card-icon">
              { SearchSVG(navigationItem?.icon) ? SearchSVG(navigationItem?.icon) : <i className={"fas " + navigationItem?.icon}></i> }
            </div>
            <div className="cc-nav-mobile-dropdown-menu-card-info">
              <p className="cc-nav-mobile-dropdown-menu-card-title">{navigationItem.title}</p>
            </div>
          </a>
        );
      });

      mobileDropdownMenu.push(
        <div key={menu.id} className={menu.menu === navigationIndustry ? "cc-nav-mobile-dropdown-menu-accordion-menu-item open" : "cc-nav-mobile-dropdown-menu-accordion-menu-item"} data-id={menu.id}>
          <a className='industry-menu' href={getUrlWithUtmQueryString(baseUrls[menu.menu] + baseUrls[menu.menu + '-link'])} title={menu.caption}><span>{menu.mobileTitle ?? menu.menu}</span></a>

          <div className="">
            <div className="cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel">
              <div className="cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-card-group-outer">
                <div className="cc-nav-mobile-dropdown-menu-accordion-menu-inner-panel-card-group-inner">
                  <div className="cc-nav-mobile-dropdown-menu-card">
                  {mobileDropdownMenuCards}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    mobileDropdownMenuCards = [];
    mobileDropdownMenuLeftTextMenu = [];
    mobileDropdownMenuLeftTextMenuItems = [];
    mobileDropdownMenuRightTextMenu = [];
    mobileDropdownMenuRightTextMenuItems = [];
  });


  return (
  <div>
    <SignInModal
      show={showSignInModal}
      onHide={handleCloseMyAccountModal}
    />
    <nav id="cc--navigation-menu-2023" ref={navigationMenuRef} className={`cc-nav-2023`}>
      <div className="cc-nav-top-level-container">
        <div className="cc-nav-top-level-container-brand">
          <a href={getUrlWithUtmQueryString(baseUrls['Mortgage'] + baseUrls['Mortgage-link'])}>
            {CapcenterHouseSVG} <img src={capcenterLogo} />
          </a>
        </div>
        <ul className="cc-nav-top-level-container-items cc-nav-top-level-container-items-left">
          <li className={"industry-menu-item"} data-id={"Mortgage"}><a href={getUrlWithUtmQueryString(baseUrls['Mortgage'] + baseUrls['Mortgage-link'])}>Purchase</a></li>
          <li className={"industry-menu-item"} data-id={"Refinance"}><a href={getUrlWithUtmQueryString(baseUrls['Refinance'] + baseUrls['Refinance-link'])}>Refinance</a></li>
          <li className={"industry-menu-item"} data-id={"Home Equity"}><a href={getUrlWithUtmQueryString(baseUrls['Home Equity'] + baseUrls['Home Equity-link'])}>Home Equity</a></li>
          <li className={"industry-menu-item"} data-id={"Realty"}><a href={getUrlWithUtmQueryString(baseUrls['Realty'] + baseUrls['Realty-link'])}>Realty</a></li>
          <li className={"industry-menu-item"} data-id={"Insurance"}><a href={getUrlWithUtmQueryString(baseUrls['Insurance'] + baseUrls['Insurance-link'])}>Insurance</a></li>
        </ul>

        <ul className="cc-nav-top-level-container-items cc-nav-top-level-container-items-right">
          <li className='telephone-number'><a href="tel:18009685844" title={'Call CapCenter'}><i className="fas fa-mobile-alt"></i> +1 (800) 968-5844</a></li>
          <li className={"industry-menu-item"} data-id={"Espanol"}><a href={getUrlWithUtmQueryString(baseUrls['Espanol'] + baseUrls['Espanol-link'])}>Español</a></li>
          <li className={"industry-menu-item"} data-id={"About"}><a href={getUrlWithUtmQueryString(baseUrls['About'] + baseUrls['About-link'])}>About</a></li>
          <li className={"industry-menu-item"} data-id={"Sign in"}><a href={getUrlWithUtmQueryString(baseUrls['Sign in'] + baseUrls['Sign in-link'])}>Account</a></li>
        </ul>
      </div>
      <div className="cc-nav-container">
        <div className="cc-nav-row">
          <ul className="cc-nav-list">
            {topMenuItems}
          </ul>
          <div className="cc-nav-right">
            <ul className="cc-nav-list-right">
              <li className="menu-item-accounts">
                <button onClick={handleShowMyAccountModal}
                  type="button">
                  <div>
                    <div><span>Get started</span></div>
                  </div>
                </button>
              </li>
              <li className="menu-item-hamburger">
                <button onClick={clickHamburgerMenu}>
                  <div className={showMobileDropdownMenu ? 'd-none' : 'hamburger-menu-icon'}>
                    { HamburgerMenuSVG }
                  </div>
                  <div className={showMobileDropdownMenu ? 'close-menu-icon' : 'd-none'}>
                    { CloseButtonSVG }
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="cc--navigation-menu-mobile-dropdown-menu" ref={mobileDropdownMenuRef} className={showMobileDropdownMenu ? "cc-nav-mobile-dropdown-menu" : "cc-nav-mobile-dropdown-menu d-none"}>
        <div className="cc-nav-mobile-dropdown-menu-container">
          <div className="cc-nav-mobile-dropdown-menu-accordion">
            {mobileDropdownMenu}

            <div className="cc-nav-mobile-dropdown-menu-accounts-div">
              <div>
                <button className="cc-nav-mobile-dropdown-menu-accounts-button cc-nav-mobile-dropdown-menu-accounts-button-solid-background" type="button" onClick={handleShowMyAccountModal}>
                  <div className="button-content-wrapper flex items-center justify-center">
                    <div className="button-content flex items-center"><span className="text-body-sm-bold">Get started</span></div>
                  </div>
                </button>
              </div>
              <div>
                <a href={getUrlWithUtmQueryString(baseUrls['Sign in'] + baseUrls['Sign in-link'])} title={'Account'}>
                  <button className="cc-nav-mobile-dropdown-menu-accounts-button" type="button">
                    <div className="button-content-wrapper flex items-center justify-center">
                      <div className="button-content flex items-center"><span className="text-body-sm-bold">Account</span></div>
                    </div>
                  </button>
                </a>
              </div>
              <div>
                <a className="cc-nav-mobile-dropdown-menu-accounts-link" href="tel:18009685844" title={'Call CapCenter'}>
                  <i className="fas fa-mobile-alt"></i>&nbsp;&nbsp;<strong>+1 (800) 968-5844</strong>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>);
}

export default AppNav;
